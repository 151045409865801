import { render, staticRenderFns } from "./sourcingso-inquiry-pc.vue?vue&type=template&id=430a28c1&scoped=true&"
import script from "./sourcingso-inquiry-pc.vue?vue&type=script&lang=js&"
export * from "./sourcingso-inquiry-pc.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/normalize.min.css?vue&type=style&index=0&id=430a28c1&scoped=true&lang=css&"
import style1 from "@/assets/css/open-props.min.css?vue&type=style&index=1&lang=css&"
import style2 from "./sourcingso-inquiry-pc.vue?vue&type=style&index=2&lang=css&"
import style3 from "./sourcingso-inquiry-pc.vue?vue&type=style&index=3&id=430a28c1&lang=less&scoped=true&"
import style4 from "./sourcingso-inquiry-pc.vue?vue&type=style&index=4&id=430a28c1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430a28c1",
  null
  
)

export default component.exports